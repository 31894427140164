.Calendar {
  padding-left: 50px;
  user-select: none;
}

.Calendar-List {
  position: relative;
  box-sizing: border-box;
  width: 366px;
  min-height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  padding: 10px 0 30px;
}

.Day {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 1px;
  text-align: center;
  padding-top: 16px;
  box-sizing: border-box;
  line-height: 1;
  position: relative;
  cursor: pointer;
}

.Day-Selected {
  background: pink;
}

.Day-Disabled {
  opacity: .5;
  cursor: default;
}

.Day-Odd {
  color: darkblue;
}

.Day-Even {
  color: green;
}

.Day-Even:not(.Day-Disabled):hover {
  outline: 1px solid green;
}

.Day-Today {
  color: red;
}

.Day:not(.Day-Disabled):hover {
  outline: 1px solid red;
}

.Day_Available {
  display: block;
  position: absolute;
  width: 85%;
}

.Month {
  color: crimson;
}

.Month {
  position: absolute;
  right: 100%;
  display: block;
  width: 150px;
  text-align: right;
  transform: rotate(270deg);
  transform-origin: 100% 0%;
  margin-top: -10px;
  margin-right: 1.7em;
}

.Day_Available + .Day_Available {
  top: 3px;
}