.App {
  height: 100%;
  overflow: auto;
  display: flex;
  min-width: 320px;
}

.Summary {
  flex: 1;
}

.Calendar {
  height: 100%;
  overflow: auto;
}

@media (max-width: 640px) {
  .App .Summary {
    display: none !important;
  }

  .App .Calendar {
    flex: 1;
    padding-left: 40px;
  }

  .App .Calendar-List {
    margin: 0 auto;
    width: 282px;
  }

  .App .Day {
    width: 40px;
    height: 40px;
    padding-top: 12px;
    font-size: 80%;
    margin: 0;
  }
}