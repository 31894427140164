.Summary {
  font-size: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Summary-Section {
  margin: .2em;
  text-align: center;
}

.Summary-Label {
  display: block;
  font-size: 20%;
}